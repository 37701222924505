.ql-toolbar.ql-snow {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.ql-container.ql-snow {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  font-size: 1rem;
  line-height: 1.5rem;
}

.ql-editor {
  height: 800px;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}

.ql-editor:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ql-editor img {
  max-width: 1000px !important;
}

.ql-editor-rtl .ql-editor {
  direction: rtl;
  text-align: right;
}

.marker {
  border-radius: 0.125rem;
  --tw-bg-opacity: 1;
  background-color: rgb(253 224 71 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.tip {
  margin-bottom: 1rem;
  display: block;
  border-radius: 0.125rem;
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

.tip::before {
  content: "i";
  margin-right: 1rem;
  display: inline-flex;
  height: 1.5rem;
  width: 1.5rem;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(239 246 255 / var(--tw-text-opacity));
}

placeholder-img {
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  max-width: 24rem;
  flex-direction: column;
  border-radius: 0.125rem;
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  vertical-align: middle;
  font-style: italic;
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

placeholder-img::before {
  content: url('data:image/svg+xml; utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="rgb(156, 163, 175)" class="w-4 h-4"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" /></svg>');
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  display: inline-block;
  height: 8rem;
  width: 8rem;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
}

.ql-container a {
  overflow-wrap: break-word;
}

.ql-container code {
  border-radius: 0.25rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  padding: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.ql-container h2 {
  margin-top: 1.5rem;
  margin-bottom: 0.25rem;
  display: block;
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 600;
}

.ql-container h3 {
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 600;
}

.ql-container h4 {
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
}

.ql-container h5 {
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
}

.ql-container h6 {
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
}

.ql-container ol, ul {
  margin-bottom: 1rem;
  list-style-position: outside;
}

.ql-container ol > :not([hidden]) ~ :not([hidden]), ul > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.ql-container ol, ul {
  padding-left: 2rem;
}

.ql-container p {
  margin-bottom: 1rem;
}

.ql-container strong {
  font-weight: 600;
}
